import "./App.css";

function App() {
  return (
    <div className="container">
      <div className="d-flex vh-100">
        <div className="side-bar p-5"></div>
        <div className="content flex-grow-1 p-5">
          <div className="d-flex flex-column">
            <nav className="mb-5">
              <ul className="d-flex justify-content-end m-0 p-0">
                <li>
                  <a className="d-block" href="https://github.com/connorbowlan/">
                    Repositories
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <main>
            <h1>About</h1>
            <i className="d-block fs-6 mb-3">June 17, 2023</i>
            <p>Hello, I'm Connor Bowlan.</p>
            <p>
              I'm a Senior Application Developer currently based in Seattle,
              Washington, United States. I have a strong background in software
              development and have worked in various roles throughout my career.
            </p>
            <p>
              I am currently employed at Project Worldwide as a Senior
              Application Developer, where I have been working full-time since
              October 2022. In this role, I collaborate with colleagues to set
              coding milestones for pre-release software projects and update
              older code bases to modern development standards, improving
              functionality and reducing operating costs. Additionally, I am
              responsible for delivering and maintaining scalable system
              architecture to support high-availability Internet sites and
              internal applications.
            </p>
            <p>
              Prior to my current position, I worked as a Software Developer at
              The Bankers Bank in Oklahoma City, Oklahoma, for four years from
              September 2018 to September 2022. During my time there, I played a
              key role in selecting coding milestones, revising code bases, and
              contributing ideas and suggestions in team meetings. I also
              delivered and maintained scalable system architecture and ensured
              high availability of Internet sites and internal applications.
            </p>
            <p>
              Before joining The Bankers Bank, I was a Self-Employed/Contract
              Software Developer at CB Development, LLC, also based in Oklahoma
              City. In this role, I worked independently and communicated with
              clients to gather requirements for development projects. I
              documented software development methodologies in technical manuals
              and created workflow diagrams to demonstrate processes and
              timelines. Additionally, I evaluated, reported, and corrected bugs
              in applications.
            </p>
            <p>
              Earlier in my career, I had the opportunity to work as a
              Technician at Apple. During this time, I assessed and diagnosed
              hardware/software issues with Apple technologies, performed
              physical repairs, and provided solutions to customers. I also
              worked closely with the retail visuals team to ensure the proper
              display of products.
            </p>
            <p>
              Throughout my professional journey, I have acquired a diverse
              skill set that includes expertise in technologies such as ASP.NET
              MVC, Microsoft SQL Server, Entity Framework, HTML5, Bootstrap,
              JavaScript, C#, jQuery, CSS, HTML, cPanel, WordPress, PHP, MySQL,
              and more. I am passionate about software development and
              constantly strive to deliver high-quality solutions. I value
              effective collaboration, attention to detail, and staying
              up-to-date with the latest industry trends and advancements.
            </p>
            <p>
              If you have any further questions or need additional details,{" "}
              <a href="mailto:connor@cbowlan.com">feel free to ask</a>.
            </p>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
